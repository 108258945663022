import { createError } from '#imports'
import type { Ref } from 'vue'

import type { HttpError } from '@backmarket/http-api'
import type { BasePageContent } from '@backmarket/http-api/src/api-specs-content'

export function assertsHomeDataExists(
  data: Ref<BasePageContent | null>,
  error: Ref<HttpError | null>,
): asserts data is Ref<BasePageContent> {
  if (error.value !== null || data.value === null) {
    throw createError({
      statusCode: error.value?.statusCode ?? 500,
      statusMessage: error.value?.message ?? '',
      fatal: true,
    })
  }
}
